.button-container-homepage {
  color: #000;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ccc;
  border-radius: 8px;
  padding-top: 2%;
  padding-bottom: 2%;
  margin-top: 2%;
  margin-bottom: 2%;
  margin-left: 2%;
  margin-right: 2%;
  text-decoration: none;
}

