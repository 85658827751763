body {
  margin: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Verdana', 'Geneva', 'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
   background-repeat: no-repeat;
}
.app {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
