.play-form-container {
  display: flex;
  justify-content: center;
/*  width: 100%;*/
  padding-left: 2%;
  padding-right: 2%;
/*  max-width: 100%;*/
/*  min-height: 100%;*/
  margin-top: 5%;
/*  position: relative;*/
}

.play-form {
  width: 440px; /*400*/
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  border-radius: 8px;
  background-color: #FFFFED;
/*  z-index: 0;*/
/*  position: relative;*/
}

.popup {
  font-size: 20px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: white;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 9999;
}


.button-container {
  position: relative;
  width: 440px;
  height: 60px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  max-width: 100%;
}

.bottom-click-div {
  position: absolute;
  height: 30px;
  width: 30px;
  border: 1px solid #000;
  color: #FFFFFF;
  border-width: 0px 3px 3px 0;
}

.bottom-clickable-div {
  position: absolute;
  height: 150%;
  width: 20%;
  cursor: pointer;
}

.bottom-clickable-div.left {
  left: 0;
  margin-left: 2%;
}

.bottom-clickable-div.right {
  right: 0;
  margin-right: 2%;
}


.bottom-click-div.left {
  margin-top: 30px;
  margin-left: 50%;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.bottom-click-div.right {
  margin-right: 8%;
  margin-top: 30px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

#mainFrame {
  height: 450px;
/*  max-width: 100%;*/
/*  min-height: 100%;*/
  margin: 3px auto;
  /*overflow: hidden;*/
  position: relative;
  user-select: none;
  pointer-events: none; 
}

#mainSecondFrame {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  pointer-events: auto;
}

#mainThirdFrame {
  width: 90%;
  margin-top: 3%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding-bottom: 87%; /* Set the aspect ratio (440 / 360) * 100 : 73%, 87*/
  pointer-events: none;
}

.image-description {
  position: absolute;
  top: 3%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 85%;
  color: black;
  pointer-events: auto;

}

.about-description {
  position: absolute;
  top: 3%;
  left: 0;
  right: 0; /* Center the element horizontally */
  font-size: 85%;
  color: black;
  text-align: center; /* Center the text content */
  padding: 5px;
}

.overlay-text {
  position: absolute;
  transform: translate(-50%, -50%);
  font-size: 115%;
  text-shadow: 2px 2px 4px #C0C0C0;
  display: flex;
  align-items: center;
  pointer-events: auto;
}

.overlay-text-nopointer {
  pointer-events: none;
  display: inline-flex;
  align-items: center;
}

.overlay-image {
  position: absolute;
  max-width: 100%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 102, 0, 0.25);
  border: 1px solid #6666;
  border-radius: 8px;
}
