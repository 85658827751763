.header {
  background-color: #ff6600;
  border-bottom: 2px solid black;
  border-right: 2px solid black;
  color: #fff;
  padding: 7px 7px;
  display: flex;
  height: 46px;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between; /* Align items to the start and end of the header */
}

.header-image img {
  width: 30px; /* Adjust the width to make the image very small */
  height: auto; /* Maintain aspect ratio */
}

.menu-icon {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 20px;
}

.bar {
  background-color: #222222;
  color: #222222;
  height: 3px;
  width: 100%;
  transition: 0.4s;
}

.open .bar:nth-child(1) {
  transform: rotate(-45deg) translate(-4px, 4px);
}

.open .bar:nth-child(2) {
  opacity: 0;
}

.open .bar:nth-child(3) {
  transform: rotate(45deg) translate(-4px, -4px);
}

.nav-links {
  padding: 0;
  visibility: hidden;
  margin-left: auto;
  padding-right: 1%;
}

.nav-links.open {
  visibility: visible;
}

.header-button {
  text-decoration: none;
  user-select: none;
  color: #000000;
  padding: 5px 5px;
  margin: 0 3px;
  text-align: center; /* Center the text within the buttons */
  transition: color 0.3s ease;
}

.header-button.highlight {
  color: #fff;
}

.welcome-message {
  color: #fff;
  margin-right: 10px;
}

.separator {
  user-select: none;
  pointer-events: none;
  color: #000;
}
