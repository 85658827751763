.fade-in-box {
  width: 440px;
  margin-top: 5%;
  max-width: 100%;
}

.sub-box-container {
  padding: 4%;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  position: relative;
  max-width: 100%;
  border: 2px solid black;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  border-radius: 8px;
  margin-left: 2%;
  margin-right: 2%;
  background-color: #FFFFED;
}

.donate-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.donate-image {
  max-width: 100%;
  height: auto;
/*  margin-bottom: 10px; */
}

.button-container-donate {
  color: #000;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ccc;
  border-radius: 8px;
  padding-top: 2%;
  padding-bottom: 2%;
  margin-top: 2%;
  margin-bottom: 2%;
  margin-left: 2%;
  margin-right: 2%;
  text-decoration: none;
  flex: auto;
}

.donate-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: #F0F8FF;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px;
  text-align: center;
  position: relative;
}

.StripeElement {
  background-color: #FFFFFF;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.donation-input-container {
  display: flex;
  justify-content: center;
  margin: 10px 0;
  margin-bottom: 10px;
}

.donation-input-container2 {
  justify-content: center;
  margin: 10px 0;
}

.donation-input {
  width: 100%;
  padding: 6px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
}

.stripe-label {
  margin-bottom: 5px;
}

.stripe-element {
  background-color: #FFFFFF; /* White background for Stripe Elements inputs */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 10px;
}

/* Blue outline on focus */
.StripeElement--focus {
  border-color: #80BDFF;
  outline:0;
  box-shadow: 0 0 0 .2rem rgba(0,123,255,.25);
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
/* Can't see what I type without this */
#card-number.form-control,
#card-cvc.form-control,
#card-exp.form-control {
    display:inline-block;
}

.stripe-box {
  padding-top: 7%;
  font-family: Inter UI, Open Sans, Segoe UI, sans-serif;
}
