.fade-in-box {
  width: 440px;
  margin-top: 5%;
  max-width: 100%;
}

.sub-box-container {
  padding: 4%;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  position: relative;
  max-width: 100%;
  border: 2px solid black;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  border-radius: 8px;
  margin-left: 2%;
  margin-right: 2%;
  background-color: #FFFFED;
}

.sub-box-row {
  display: flex;
  justify-content: center; /* Center the rows */
  width: 100%;
}

.sub-box {
  width: 45%; /* Adjusted width for better spacing on mobile */
  max-width: 240px; /* Adjusted width for the larger emojis */
  height: 120px; /* Adjusted height for the larger emojis */
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 2px;
  border-radius: 8px;
  margin: 2px;
  box-sizing: border-box;
  transition: background-color 0.3s ease-in-out;
  font-size: 48px; /* Increased font size for larger emojis */
}

.sub-box.active {
  background-color: #3D8AB0; /* Change to your desired background color */
  transition: background-color 0.3s ease-in-out;
}

.instructions {
  color: #000;
  position: relative;
  opacity: 1;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  margin: 2%;
}

.instructions.hidden {
  opacity: 0;
}

.sub-box span {
  font-size: 36px; /* Adjust the font size of the emoji */
}

.sub-box .country-name {
  margin-top: 55px;
  font-size: 14px;
}

.click-through {
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  user-select: none;
}

.button-container-homepage {
  color: #000;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ccc;
  border-radius: 8px;
  padding-top: 2%;
  padding-bottom: 2%;
  margin-top: 2%;
  margin-bottom: 2%;
  margin-left: 2%;
  margin-right: 2%;
  text-decoration: none;
}

.instructions-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.instructions-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px; /* Adjust as needed */
}

.language-select-image {
  height: 70px;
  position: absolute;
  margin-bottom: 8%;
}
