.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 5%;
  box-sizing: border-box;
  position: relative; /* Add position relative for child positioning */
}

.Auth-form {
  width: 90%; /* Adjusted width for responsiveness */
  max-width: 420px; /* Set a maximum width to avoid excessive expansion */
  padding: 30px 20px; /* Adjust padding for better mobile display */
  border-radius: 8px;
  box-shadow: 1px 1px 10px #00000029;
  margin-top: 1.3%;
  border: 2px solid #000;
  background-color: #ffffed;
  position: relative;
  z-index: 1; /* Ensure the form is above the background */
}

.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.Auth-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  color: rgb(34, 34, 34);
  font-weight: 800;
}

label {
  font-size: 14px;
  font-weight: 600;
  color: rgb(34, 34, 34);
}
