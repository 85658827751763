.centered-container {
  text-align: center;
}

.pagination-links {
  margin-top: 10px;
}

.sitemap-list {
  list-style: none;
  padding: 0;
}

.sitemap-list li {
  margin-bottom: 5px;
}

.separator {
  margin: 0 5px;
}
